/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from '@i18n';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { miniDrawerWidthSeller, drawerWidthSeller } from '@modules/theme/layout/helpers';
import { PRIMARY, PRIMARY_DARK, TABLE_GRAY } from '@theme_color';
import { getLocalStorage } from '@helper_localstorage';
import { storeConfigVar, storeConfigPimVar } from '@core/services/graphql/cache';

import Tooltip from '@common_tooltipseller';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidthSeller,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidthSeller,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        border: '0',
        boxShadow: '2px 0px 20px #4D2F821A',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: miniDrawerWidthSeller,
        },
        border: '0',
        boxShadow: '2px 0px 20px #4D2F821A',
    },
    togleMenuButton: {
        width: 24,
        height: 24,
        position: 'absolute',
        top: '16px',
        right: '16px',
    },
    togleMenuIcon: {
        fontSize: 27,
        color: PRIMARY,
        borderRadius: '3px',
        background: '#fff',
        boxShadow: '0px 3px 6px #DDE1EC',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    swiftOmsLogo: {
        cursor: 'pointer',
        margin: 12,
        marginBottom: 0,
        paddingBottom: 12,
        justifyContent: 'center',
        '& img': {
            height: 45,
            marginTop: 10,
        },
    },

    divMenu: {
        color: '#B1BCDB',
        '& .itemText span': {
            fontWeight: 'bold',
            fontSize: 14,
            textTransform: 'capitalize',
        },
        justifyContent: 'center',
        '&.open': {
            '& .itemIcon': {
                minWidth: 0,
                marginRight: 12,
            },
        },
        '&.close': {
            padding: '0 12px',
        },
        '& img': {
            width: 20,
            height: 'auto',
        },
    },
    menuList: {
        padding: 0,
        '&.open': {
            padding: '0 25px',
        },
    },
    menuItem: {
        marginTop: 20,
        borderRadius: 12,
        '&.close': {
            paddingLeft: 13,
        },
        '& .itemAnchor': {
            color: '#B1BCDB',
            justifyContent: 'end',
        },
        '&:hover': {
            background: TABLE_GRAY,
            color: PRIMARY,
            '& .itemIcon img': {
                filter: 'invert(19%) sepia(7%) saturate(5640%) hue-rotate(63deg) brightness(67%) contrast(94%)',
            },
            '& .itemAnchor': {
                color: PRIMARY,
            },
        },
        '&.active': {
            background: TABLE_GRAY,
            color: PRIMARY,
            '& .itemIcon img': {
                filter: 'invert(19%) sepia(7%) saturate(5640%) hue-rotate(63deg) brightness(67%) contrast(94%)',
            },
            '& .itemAnchor': {
                color: PRIMARY,
            },

        },
    },
    menuChildItem: {
        marginTop: 10,
        paddingLeft: 50,
        borderRadius: 12,
        '& .itemAnchor': {
            color: '#B1BCDB',
            justifyContent: 'end',
        },
        '&.active': {
            background: TABLE_GRAY,
            '& span': {
                color: PRIMARY,
                fontWeight: 'bold',
            },
            '& .itemAnchor': {
                color: PRIMARY,
            },
        },
        '&:hover': {
            background: TABLE_GRAY,
            '& span': {
                color: PRIMARY,
                fontWeight: 'bold',
            },
            '& .itemAnchor': {
                color: PRIMARY,
            },
        },
    },
    menuGrandChildItem: {
        marginTop: 10,
        paddingLeft: 70,
        borderRadius: 12,
        '&.active': {
            background: TABLE_GRAY,
            '& span': {
                color: PRIMARY,
                fontWeight: 'bold',
            },
        },
        '&:hover': {
            background: TABLE_GRAY,
            '& span': {
                color: PRIMARY,
                fontWeight: 'bold',
            },
        },
    },
    divBorder: {
        borderTop: '1px solid #9E90BD',
        '&.close': {
            margin: '0 10px',
        },
    },
    divMenuBalance: {
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid #9E90BD',
        borderBottom: '1px solid #9E90BD',
        padding: '15px 0',
        color: PRIMARY_DARK,
        '& .itemText': {
            fontSize: 14,
            textTransform: 'capitalize',
        },
        '& .balance': {
            color: PRIMARY,
            fontWeight: 'bold',
        },
        '&.close': {
            visibility: 'hidden',
        },
    },
    menuItemBalance: {
        '& .itemIcon': {
            filter: 'invert(19%) sepia(7%) saturate(5640%) hue-rotate(63deg) brightness(67%) contrast(94%)',
            width: 12,
            height: 12,
            marginRight: 10,
        },
    },
    divKatalisBalance: {
        marginTop: 14,
        borderRadius: 8,
        border: '1px solid #9E90BD',
        padding: 10,
        color: PRIMARY_DARK,
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        '& .get-more': {
            fontSize: 11.5,
            width: 'fit-content',
            '& a': {
                display: 'flex',
                alignItems: 'center',
            },
            '&:hover': {
                color: PRIMARY,
            },
            '& .icon-get-more': {
                width: 20,
                height: 20,
            },
        },
        '& .expired': {
            fontSize: 11.5,
            '& .link': {
                color: PRIMARY,
            },
        },
        '&.close': {
            margin: '0 10px',
            marginTop: 14,
            alignItems: 'center',
        },
    },
    creditKatalis: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '& .katalisIcon': {
            width: 18,
            height: 18,
        },
        '& .credit': {
            color: PRIMARY_DARK,
            fontSize: 14,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: 5,
        },
        '&.close': {
            '& .katalisIcon': {
                width: 20,
                height: 20,
            },
        },
    },
    refreshKatalisButton: {
        padding: 0,
    },
    refreshKatalisIcon: {
        width: 18,
        height: 18,
    },
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(720deg)',
        },
    },
    refreshKatalisIconLoading: {
        width: 18,
        height: 18,
        animation: '$spin 2s linear infinite',
    },
    arrowDown: {
        transform: 'rotate(90deg)',
    },
    arrowUp: {
        transform: 'rotate(-90deg)',
    },
}));

const Sidebar = ({
    activeParentMenu,
    setActiveParentMenu,
    activeChildMenu,
    setActiveChildMenu,
    activeGrandChildMenu,
    setActiveGrandChildMenu,
    open,
    setOpen,
    menuList,
    storeLogo,
    katalisBalance,
    katalisState,
}) => {
    const router = useRouter();
    const classes = useStyles();
    const { t } = useTranslation('common');
    const handleClickParent = (menu) => {
        if (menu.key === (activeParentMenu && activeParentMenu.key)) {
            setActiveParentMenu(null);
        } else {
            setActiveParentMenu(menu);
            if (menu.url) router.push(menu.url);
        }
        setOpen(true);
    };
    const handleClickChild = (menu) => {
        if (menu.key === (activeChildMenu && activeChildMenu.key)) {
            setActiveChildMenu(null);
        } else {
            setActiveChildMenu(menu);
            if (menu.url) router.push(menu.url);
        }
        setOpen(true);
    };
    const handleClickGrandChild = (menu) => {
        if (menu.key === (activeGrandChildMenu && activeGrandChildMenu.key)) {
            setActiveGrandChildMenu(null);
        } else {
            setActiveGrandChildMenu(menu);
            if (menu.url) router.push(menu.url);
        }
        setOpen(true);
    };

    const aclDetail = getLocalStorage('acl') ? JSON.parse(getLocalStorage('acl')) : {};
    const balance = getLocalStorage('seller_balance');

    const storeConfig = storeConfigVar();
    const storeConfigPim = storeConfigPimVar();
    const isMultichannel = storeConfig?.swiftoms_vendor_enable_multi_channel === '1';

    const getArrowClass = (anchor) => (anchor ? classes.arrowUp : classes.arrowDown);

    const SidebarContent = () => (
        <>
            <div
                className={clsx(classes.toolbar, classes.swiftOmsLogo, open ? 'open' : 'close')}
                onClick={() => router.push('/seller/order/orderlist')}
                onKeyDown={() => router.push('/seller/order/orderlist')}
            >
                <img
                    alt=""
                    src={open ? storeLogo?.logo || '/assets/img/swiftoms_logo_expanded.png'
                        : storeLogo?.favicon || '/assets/img/swiftoms_logo_collapsed.png'}
                />
            </div>
            <Hidden smUp>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => setOpen(false)}
                    className={clsx(classes.togleMenuButton)}
                >
                    <ChevronLeftIcon className={classes.togleMenuIcon} />
                </IconButton>
            </Hidden>
            <List className={clsx(classes.menuList, open ? 'open' : 'close')}>
                {aclDetail?.acl_code?.includes('seller_income') ? (
                    <div className={clsx(classes.divMenuBalance, open ? 'open' : 'close')}>
                        <div className={classes.menuItemBalance}>
                            <img className="itemIcon" alt="" src="/assets/img/layout/seller/income.svg" />
                            <span className="itemText">{t('common:Balance')}</span>
                        </div>
                        <div className="balance">
                            {balance}
                        </div>
                    </div>
                ) : <div className={clsx(classes.divBorder, open ? 'open' : 'close')} />}
                {katalisState?.status !== null && !!storeConfigPim.katalis_application_enable && (open
                    ? (
                        <div className={classes.divKatalisBalance}>
                            <div className={classes.creditKatalis}>
                                <img className="katalisIcon" alt="" src="/assets/img/katalis.png" />
                                <div className="credit">
                                    {t('common:Credit')}
                                    {': '}
                                    {katalisBalance.loading
                                        ? <Skeleton variant="rect" width={8 * (String(katalisState.balance)?.length || 1)} height={12} animation="wave" />
                                        : katalisState.balance?.toString() ? katalisState.balance : '-'}
                                </div>
                                <Tooltip
                                    title={t('common:Credits_are_used_to_access_the_image_generation__text_generation_using_Katalis_AI')}
                                />
                                {katalisBalance.loading
                                    ? <img className={classes.refreshKatalisIconLoading} src="/assets/img/arrow-path.svg" alt="arrow-path" />

                                    : (
                                        <Tooltip
                                            title={t('common:Refresh')}
                                        >
                                            <IconButton classes={{ root: classes.refreshKatalisButton }} onClick={katalisBalance.refetch}>
                                                <img className={classes.refreshKatalisIcon} src="/assets/img/arrow-path.svg" alt="arrow-path" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                            </div>
                            {katalisState?.status === false
                                && (
                                    <div className="expired">
                                        {t('common:Your_account_has_been_disconnected')}
                                        <div>
                                            {t('common:reintegrate')}
                                            {' '}
                                            <Link href="/seller/apps" passHref>
                                                <a className="link" target="_blank" rel="noreferrer">
                                                    {t('common:here')}
                                                </a>
                                            </Link>
                                            .
                                        </div>
                                    </div>
                                )}
                            {!!storeConfigPim.katalis_purchase_credit_url && (
                                <>
                                    <Divider />
                                    <div className="get-more">
                                        <Link href={storeConfigPim.katalis_purchase_credit_url} className="link" passHref>
                                            <a target="_blank" rel="noreferrer">
                                                {`${t('common:Get_more_credits')}`}
                                                <KeyboardArrowRightIcon className="icon-get-more" />
                                            </a>
                                        </Link>
                                    </div>
                                </>
                            )}
                        </div>
                    )
                    : (
                        <div className={clsx(classes.divKatalisBalance, 'close')}>
                            <div className={clsx(classes.creditKatalis, 'close')}>
                                <img className="katalisIcon" alt="" src="/assets/img/katalis.png" />
                            </div>
                        </div>
                    )
                )}
                {menuList?.map((menu) => {
                    if ((isMultichannel && menu.isMultichannel === false) || (!isMultichannel && menu.isMultichannel === true)) {
                        return null;
                    }
                    return (
                        <div key={menu.key}>
                            {aclDetail && (aclDetail?.acl_code?.includes(menu.aclCode) || menu.notInAcl) && (
                                <div className={clsx(classes.divMenu, open ? 'open' : 'close')} key={menu.key}>
                                    {menu.url
                                        ? (
                                            <Link href={`${menu.url}`} key={menu.key}>
                                                <a>
                                                    <ListItem
                                                        button
                                                        className={clsx(
                                                            classes.menuItem,
                                                            open ? 'open' : 'close',
                                                            menu.key === (activeParentMenu && activeParentMenu.key) && 'active',
                                                        )}
                                                        onClick={() => handleClickParent(menu)}
                                                    >
                                                        <ListItemIcon className="itemIcon">
                                                            <img alt="" src={`/assets/img/layout/seller/${menu.key}.svg`} />
                                                        </ListItemIcon>
                                                        <ListItemText className="itemText" primary={menu.label} />
                                                    </ListItem>
                                                </a>
                                            </Link>
                                        )
                                        : (
                                            <ListItem
                                                button
                                                className={clsx(
                                                    classes.menuItem,
                                                    open ? 'open' : 'close',
                                                    menu.key === (activeParentMenu && activeParentMenu.key) && 'active',
                                                )}
                                                onClick={() => handleClickParent(menu)}
                                            >
                                                <ListItemIcon className="itemIcon">
                                                    <img alt="" src={`/assets/img/layout/seller/${menu.key}.svg`} />
                                                </ListItemIcon>
                                                <ListItemText className="itemText" primary={menu.label} />
                                                <ListItemIcon className="itemAnchor">
                                                    <KeyboardArrowRightIcon className={getArrowClass(menu.key === (activeParentMenu && activeParentMenu.key))} />
                                                </ListItemIcon>
                                            </ListItem>
                                        )}

                                    {menu && menu.children && !!menu.children.length && (
                                        <Collapse in={activeParentMenu && activeParentMenu.key === menu.key} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {menu.children.map((menuChild) => (
                                                    <div key={menuChild.key}>
                                                        {((aclDetail && aclDetail?.acl_code?.includes(menuChild.aclCode)) || menuChild.notInAcl) && (
                                                            menuChild.url
                                                                ? (
                                                                    <Link href={`${menuChild.url}`} key={menuChild.key}>
                                                                        <a>
                                                                            <ListItem
                                                                                button
                                                                                key={menuChild.key}
                                                                                className={clsx(
                                                                                    classes.menuChildItem,
                                                                                    menuChild.key === (activeChildMenu && activeChildMenu.key)
                                                                                    && 'active',
                                                                                )}
                                                                                onClick={() => handleClickChild(menuChild)}
                                                                            >
                                                                                <ListItemText className="itemText" primary={menuChild.label} />
                                                                            </ListItem>
                                                                        </a>
                                                                    </Link>
                                                                )
                                                                : (
                                                                    <ListItem
                                                                        button
                                                                        key={menuChild.key}
                                                                        className={clsx(
                                                                            classes.menuChildItem,
                                                                            menuChild.key === (activeChildMenu && activeChildMenu.key)
                                                                            && 'active',
                                                                        )}
                                                                        onClick={() => handleClickChild(menuChild)}
                                                                    >
                                                                        <ListItemText className="itemText" primary={menuChild.label} />
                                                                        <ListItemIcon className="itemAnchor">
                                                                            <KeyboardArrowRightIcon className={getArrowClass(menuChild.key === (activeChildMenu && activeChildMenu.key))} />
                                                                        </ListItemIcon>
                                                                    </ListItem>
                                                                )
                                                        )}

                                                        {menuChild && menuChild.children && !!menuChild.children.length && (
                                                            <Collapse in={activeChildMenu && activeChildMenu.key === menuChild.key} timeout="auto" unmountOnExit>
                                                                <List component="div" disablePadding>
                                                                    {menuChild.children.map((menuGrandChild) => (
                                                                        <div key={menuGrandChild.key}>
                                                                            {((aclDetail && aclDetail?.acl_code?.includes(menuGrandChild.aclCode)) || menuGrandChild.notInAcl) && (
                                                                                menuGrandChild.url
                                                                                    ? (
                                                                                        <Link href={`${menuGrandChild.url}`} key={menuGrandChild.key}>
                                                                                            <a>
                                                                                                <ListItem
                                                                                                    button
                                                                                                    key={menuGrandChild.key}
                                                                                                    className={clsx(
                                                                                                        classes.menuGrandChildItem,
                                                                                                        menuGrandChild.key === (activeGrandChildMenu && activeGrandChildMenu.key)
                                                                                                        && 'active',
                                                                                                    )}
                                                                                                    onClick={() => handleClickGrandChild(menuGrandChild)}
                                                                                                >
                                                                                                    <ListItemText className="itemText" primary={menuGrandChild.label} />
                                                                                                </ListItem>
                                                                                            </a>
                                                                                        </Link>
                                                                                    )
                                                                                    : (
                                                                                        <ListItem
                                                                                            button
                                                                                            key={menuGrandChild.key}
                                                                                            className={clsx(
                                                                                                classes.menuGrandChildItem,
                                                                                                menuGrandChild.key === (activeGrandChildMenu && activeGrandChildMenu.key)
                                                                                                && 'active',
                                                                                            )}
                                                                                            onClick={() => handleClickGrandChild(menuGrandChild)}
                                                                                        >
                                                                                            <ListItemText className="itemText" primary={menuGrandChild.label} />
                                                                                            <ListItemIcon className="itemAnchor">
                                                                                                <KeyboardArrowRightIcon className={getArrowClass(menu.key === (activeParentMenu && activeParentMenu.key))} />
                                                                                            </ListItemIcon>
                                                                                        </ListItem>
                                                                                    )
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </List>
                                                            </Collapse>
                                                        )}

                                                    </div>
                                                ))}
                                            </List>
                                        </Collapse>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </List>
        </>
    );

    const SidebarMobile = () => (
        <Drawer
            variant="temporary"
            open={open}
            onClose={() => setOpen(false)}
            className={clsx(classes.drawer, open ? classes.drawerOpen : classes.drawerClose)}
            classes={{
                paper: clsx(open ? classes.drawerOpen : classes.drawerClose),
            }}
            ModalProps={{ keepMounted: true }}
        >
            {SidebarContent()}
        </Drawer>
    );

    const SidebarDesktop = () => (
        <Drawer
            variant="permanent"
            open={open}
            className={clsx(classes.drawer, open ? classes.drawerOpen : classes.drawerClose)}
            classes={{
                paper: clsx(open ? classes.drawerOpen : classes.drawerClose),
            }}
        >
            {SidebarContent()}
        </Drawer>
    );

    return (
        <>
            <Hidden smUp>{SidebarMobile()}</Hidden>
            <Hidden xsDown>{SidebarDesktop()}</Hidden>
        </>
    );
};

export default Sidebar;
