import { gql } from '@apollo/client';

export const customerAccessControlList = gql`
    query{
        customerAccessControlList{
            acl_code
        }
    }
`;

export const getStoreConfig = gql`
    query getStoreConfig(
        $path: String!
    ){
        getStoreConfig(
            path: $path
        )
    }
`;

export const isAccessAllowed = gql`
    query isAccessAllowed(
        $acl_code: String!
    ){
        isAccessAllowed(
            acl_code: $acl_code
        )
    }
`;

export const getStoreLogo = gql`
query{
    getStoreLogo{
      favicon
      logo
    }
  }
`;

export const getCurrency = gql`
query {
    currency {
      base_currency_code
      base_currency_symbol
      default_display_currency_code
      default_display_currency_symbol
      available_currency_codes
      exchange_rates {
        currency_to
        rate
      }
    }
  }
  `;

export const csStores = gql`
query csStores {
    csStores (first: 1000, page: 1) {
    data {
      channel {
        channel_name
        channel_code
      }
    }
  }
}
`;

export const storeConfigMenu = gql`
query storeConfigMenu {
  storeConfig{
      swiftoms_pickpack_wave_enable
      swiftoms_pickpack_batch_enable
      swiftoms_tada_enable
      swiftoms_enable_vendor_portal
  }
}
`;

export const updateCustomerAttribute = gql`
mutation updateCustomerAttribute($company_id: String!) {
  updateCustomerAttribute(customer_attribute: { customer_company_code: $company_id }) {
    id
  }
}
`;

export const katalisGetIntegration = gql`
query katalisGetIntegration {
  katalisGetIntegration {
    created_at
    integration_id
    status
    updated_at
  }
}
`;

export const katalisGetCreditBalance = gql`
query katalisGetCreditBalance {
  katalisGetCreditBalance
}
`;

export default {
    customerAccessControlList,
    getStoreConfig,
    isAccessAllowed,
    getCurrency,
    csStores,
    storeConfigMenu,
    updateCustomerAttribute,
    katalisGetIntegration,
    katalisGetCreditBalance,
};
