/* eslint-disable import/prefer-default-export */
export const optionsPhastos = (t) => [
    {
        aclCode: 'phastos_login',
        key: 'phastos',
        label: t('menu:Phastos'),
        children: [
            {
                aclCode: 'phastos_company_dashboard',
                key: 'phastoscompany',
                label: t('menu:Company'),
                url: '/phastos/company',
            },
            {
                aclCode: 'phastos_location_dashboard',
                key: 'phastoslocation',
                label: t('menu:Location'),
                url: '/phastos/location',
            },
            {
                aclCode: 'phastos_saleschannel_dashboard',
                key: 'phastossaleschannel',
                label: t('menu:Sales_Channel'),
                url: '/phastos/saleschannel',
            },
            {
                aclCode: 'phastos_virtual_stock_dashboard',
                key: 'phastosvirtualstock',
                label: t('menu:Virtual_Stock'),
                url: '/phastos/virtualstock',
            },
            {
                aclCode: 'phastos_catalog_product_entity_dashboard',
                key: 'phastosproduct',
                label: t('menu:Product'),
                url: '/phastos/product',
            },
            {
                aclCode: 'phastos_sku_assembly_configuration_dashboard',
                key: 'phastosproductassembly',
                label: t('menu:Product_Assembly'),
                url: '/phastos/productassembly',
            },
            {
                aclCode: 'phastos_sourcing_dashboard',
                key: 'phastossource',
                label: t('menu:Source'),
                url: '/phastos/source',
            },
            {
                aclCode: 'phastos_audit_trail_dashboard',
                key: 'phastosaudittrail',
                label: t('menu:Audit_Trail'),
                url: '/phastos/audittrail',
            },
            {
                aclCode: 'phastos_allocation_rules_component_dashboard',
                key: 'phastosallocationrulescomponent',
                label: t('menu:Allocation_Rules_Component'),
                url: '/phastos/allocationrules_component',
            },
            {
                aclCode: 'phastos_allocation_rules_item_dashboard',
                key: 'phastosallocationrulesitem',
                label: t('menu:Allocation_Rules_Item'),
                url: '/phastos/allocationrules_item',
            },
            {
                aclCode: 'phastos_channel_mirroring_dashboard',
                key: 'phastoschannelmirroringgroup',
                label: t('menu:Channel_Mirroring_Group'),
                url: '/phastos/channelmirroringgroup',
            },
            {
                aclCode: 'phastos_channel_stock_allocation_dashboard',
                key: 'phastoschannelstockallocation',
                label: t('menu:Channel_Stock_Allocation'),
                url: '/phastos/channelstockallocation',
            },
            {
                aclCode: 'phastos_threshold_limit_config',
                key: 'phastosthresholdlimit',
                label: t('menu:Threshold_Limit'),
                url: '/phastos/thresholdlimit',
            },
            {
                aclCode: 'phastos_override_stock',
                key: 'phastosoverridestock',
                label: t('menu:Override_Stock'),
                url: '/phastos/overridestock',
            },
            {
                aclCode: 'phastos_cap_limit_config',
                key: 'phastoscaplimitconfig',
                label: t('menu:Cap_Limit_Config'),
                url: '/phastos/caplimitconfig',
            },
        ],
    },
];
